<template>
  <Page>
    <template v-slot:content>
      <Section id="error-page">
        <EmptyResults
          :title="$t('pages.errorpage.title')"
          :subtitle="$t('pages.errorpage.subtitle')"
        >
          <template v-slot:buttons>
            <div class="button--container">
              <Button
                type="tertiary"
                :label="$t('pages.errorpage.back')"
                @onClick="backHomePage"
              />
              <Button
                :label="$t('pages.errorpage.search')"
                @onClick="backSearchPage"
              />
            </div>
          </template>
        </EmptyResults>
      </Section>
    </template>
  </Page>
</template>

<script>
import { Page } from "./../../templates";

import { PAGES } from "@/enums/pages";

import { EmptyResults, Section, Button } from "@sc/ds-ui-component-library";

export default {
  name: "ErrorPage",
  components: {
    Page,
    EmptyResults,
    Section,
    Button,
  },
  methods: {
    backHomePage() {
      console.debug("Back to HomePage...");
      this.$router.push({
        name: PAGES.HOME,
      });
    },
    backSearchPage() {
      console.debug("Back to SearchPage...");
      this.$router.push({
        name: PAGES.SEARCH,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button--container {
  display: flex;
  gap: 24px;
}

.empty-results--outer {
  margin-top: 150px;
  margin-bottom: 150px;
  --heading-xlarge-font-size: 96px;
  --heading-large-font-size: 24px;
  --heading-medium-font-size: 16px;
}

::v-deep .empty-results--inner {
  --empty-results-inner-gap: 64px;
}

#error-page {
  --empty-results-inner-max-width: 70%;
}

::v-deep .h1 {
  --heading-weight-bold: 800;
  --heading-xlarge-font-size: 80px;
  --heading-xlarge-line-height: 102px;
  --heading-color: var(--theme-color-tertiary);
}

::v-deep .h2 {
  --heading-weight-bold: bold;
  --heading-large-font-size: 18px;
  --heading-large-line-height: 23px;
  --heading-color: var(--color-2);
}

@media (max-width: 1024px) {
  ::v-deep .section {
    --section-padding: 60px 10px;
  }
}

@media (max-width: 768px) {
  #error-page {
    --empty-results-inner-max-width: 80%;
  }
  ::v-deep .section {
    --section-padding: 0px;
  }
  .button--container {
    flex-direction: column;
  }
}
</style>
